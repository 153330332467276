import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BottomNavigationBar from '../bottom-navigation-bar/bottom-navigation-bar';
import Preloader from '../preloader/preloader';
import ScanProductBarcodePopUp from '../../screens/scan-product-barcode-popup/scan-product-barcode-popup';
import ScanPermissionErrorPopUp from '../../screens/scan-permission-error-popup/scan-permission-error-popup';
import SurveyPopUp from '../../screens/feedback-survey-popup/feedback-survey-popup';
import { setLocaleData } from '../../actions/locale';
import { setManualCodeVisibility } from '../../actions/scan';
import { setOffers } from '../../actions/offers';
import './layout.scss';
import Util from '../../utils/util';
import CommonToast from '../common-toast/common-toast';
class Layout extends React.Component {
    state = {
        isScannerPermissionError: false,
        isBarcodeScannerOpen: false,
        isPreloaderVisible: false,
        isSectionPreloader: false,
        isErrorCode: false,
        isErrorToast: false,
        scannerType: Util.SCANNER_PRODUCT_TYPE,
        isToastVisible: false,
        isErrorCommonToast: false,
        toastMessage: [],
        toastType: "",
        actionLabel: "",
        actionCallback: null,
        toastHtmlContent: "",
        hasClose: false,
        surveyVisible:false
    }
    componentDidMount() {
        window.toggleErrorScanWindow = this.toggleErrorScanWindow;
        window.toggleInvalidItemErrorToast = this.toggleInvalidItemErrorToast;
        window.toggleBarcodeScanner = this.toggleBarcodeScanner;
        window.togglePreloader = this.togglePreloader;
        window.toggleScanPermissionErrorWindow = this.toggleScanPermissionErrorWindow;
        window.isScannerOpen = this.isScannerOpen;
        window.toggleCommonToast = this.toggleCommonToast;
        toast.configure();
        const isLoggedIn = Util.isUserLoggedIn();
        const insiderGateGroup = Util.getInsiderGateGroup();
        if (window.location.pathname.indexOf('/store/') >= 0 || window.location.pathname === '/') {
            // No actions required as this paths is accesed besfore storeinfo call
        }
        else {
            if (Util.isOverlayExceptionUrl() && insiderGateGroup != Util.INSIDER_GATE_GROUPS.WITHOUT_INSIDER_GATE) {
                if (!Util.isECatalogueFlow()) {
                    if (isLoggedIn != Util.LOGGEDIN_STATUS.LOGGED_IN) {
                        Util.gotoRoute("/home", this.props.history);
                    }
                }
            }
        }
        
        // REMOVED AS CODE IS THROWING ERROR IN NEW VERSIONS OF BROWSERS
        // window.addEventListener('beforeinstallprompt', (e) => {
        //     e.prompt();
        //     e.userChoice
        //         .then((choiceResult) => {
        //             if (choiceResult.outcome === 'accepted') {
        //                 console.log('User accepted the A2HS prompt');
        //             } else {
        //                 console.log('User dismissed the A2HS prompt');
        //             }
        //         });
        // });

        // Checking for camera permission issue flag
        const cameraPermissionFlag = Util.getStringFromStorage(Util.CAMERA_PERMISSION_FLAG);
        if (cameraPermissionFlag && cameraPermissionFlag === 'true') {
            window.toggleBarcodeScanner();
        }
        if (window.location.pathname.indexOf('/store') > -1) {
            let selectedlanguage = Util.getStringFromStorage(Util.APP_LANGUAGE);
            if (selectedlanguage == 'en') {
                Util.addStringToStorage(Util.APP_LANGUAGE, 'en-us');
            }
        }
        // Load Language
        this.setLocaleFile();
    }
     toggleBarcodeScanner = (isShow = true, scannerType = Util.SCANNER_PRODUCT_TYPE, callback = () => {}) => {
        if (/iPhone|iPad|iPod/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent)&& 
        !/Edg/.test(navigator.userAgent)) {
        if (isShow) {
            this.props.setManualCodeVisibility(false);
            if (window.webkit && window.webkit.messageHandlers) {
                window.webkit.messageHandlers.ios.postMessage("openCamera");
            }
            
        } else {
            if (window.webkit && window.webkit.messageHandlers) {
                window.webkit.messageHandlers.ios.postMessage("closeCamera");
            }
        }
    }
        this.setState({
            ...this.state,
            scannerType: scannerType,
            isErrorCode: false,
            isErrorToast: false,
            isBarcodeScannerOpen: isShow}, _ => {
                if(this.state.isBarcodeScannerOpen) {
                    dataLayer.push({
                        'event': 'appPageView',
                        'appPageName': 'barcode scan page',
                        'flowConfig': Util.getGAFlowConfig()
                      });
                    document.querySelector('.scan-product-barcode').focus();
                    if (window.barcodePicker) {
                        if (!window.barcodePicker.cameraAccess) {
                            window.barcodePicker.accessCamera().then(picker => {
                                window.barcodePicker = picker;
                                window.togglePauseScan(!this.state.isBarcodeScannerOpen);
                            })
                            .catch(err => {
                                console.log(err);
                            });
                        } else {
                            window.togglePauseScan(!this.state.isBarcodeScannerOpen);
                        }
                    }
                } else {
                    window.togglePauseScan(!this.state.isBarcodeScannerOpen);
                }
            }, callback);
    }
    togglePreloader = (isShow = true, sectionPreloader = false) => {
        this.setState({
            isSectionPreloader: sectionPreloader,
            isPreloaderVisible: isShow
        });
    }
    toggleInvalidItemErrorToast = (isShow = true) => {
        this.setState({
            ...this.state,
            isErrorCode: false,
            isErrorToast: isShow,
            isBarcodeScannerOpen: isShow,
        });
    }
    isScannerOpen = () => {
        return this.state.isBarcodeScannerOpen;
    }
    toggleErrorScanWindow = (isShow = true) => {
        this.setState({
            ...this.state,
            isErrorCode: isShow,
            isErrorToast: false,
            isBarcodeScannerOpen: isShow,
        });
    }
    toggleScanPermissionErrorWindow = (isShow = true) => {
        this.setState({isScannerPermissionError: isShow});
    }
    toggleCommonToast = (isShow = true, message = [], isErrorCommonToast = false, toastTimeout = Util.TOAST_TIMEOUT, onScrollHide = true, type = Util.TOAST_TYPE.NORMAL, toastActionLabel = "", toastActionCallback = "", toastHtmlContent = "", hasClose = false) => {
        if (isShow) {
            this.setState({
                isScannerPermissionError: this.state.isScannerPermissionError,
                isBarcodeScannerOpen: this.state.isBarcodeScannerOpen,
                isPreloaderVisible: this.state.isPreloaderVisible,
                isSectionPreloader: this.state.isSectionPreloader,
                isErrorCode: this.state.isErrorCode,
                isErrorToast: this.state.isErrorToast,
                scannerType: this.state.scannerType,
                isToastVisible: false,
                isErrorCommonToast,
                toastMessage: message,
                toastType: type,
                actionLabel: toastActionLabel,
                actionCallback: toastActionCallback,
                toastHtmlContent: toastHtmlContent,
                hasClose: hasClose
            }, () => {
                const {isErrorCommonToast, toastMessage, toastType, actionLabel, actionCallback, toastHtmlContent, hasClose } = this.state;
                let toastId;
                if (isErrorCommonToast) {
                    toastId = toast.error(<CommonToast isError={isErrorCommonToast} message={toastMessage} toastType={toastType} actionLabel={actionLabel} actionCallback={actionCallback} toastHtmlContent={toastHtmlContent}/>, {
                        position: "top-center",
                        autoClose: toastTimeout || false,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        closeButton: hasClose ? true : false
                    });
                } else {
                    toastId = toast(<CommonToast isError={isErrorCommonToast} message={toastMessage} toastType={toastType} actionLabel={actionLabel} actionCallback={actionCallback} toastHtmlContent={toastHtmlContent}/>, {
                        position: "top-center",
                        autoClose: toastTimeout || false,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                }
                // this.setState({isToastVisible: true});
                // if(window.commonToastTimeout){
                //     clearTimeout(window.commonToastTimeout);
                // }
                if (onScrollHide) {
                    window.addEventListener('scroll', (event) => {
                        if (event) {
                            // if (window.commonToastTimeout) {
                            //     clearTimeout(window.commonToastTimeout);
                            // }
                            // this.setState({isToastVisible: false}); 
                            toast.dismiss(toastId);
                        }
                    });
                }
                // if (toastTimeout) {
                //     window.commonToastTimeout = window.setTimeout(() => {
                //         this.setState({isToastVisible: false});    
                //     }, toastTimeout);   
                // }
            });
        } else {
            // this.setState({isToastVisible: false});
            toast.dismiss();
        }
    }
    render() {
        const { isBarcodeScannerOpen, isPreloaderVisible, isSectionPreloader, isErrorCode, isErrorToast, isScannerPermissionError, scannerType, isToastVisible, isErrorCommonToast, toastMessage, toastType, actionLabel, actionCallback, toastHtmlContent, hasClose } = this.state;
        var screen = document.getElementsByClassName('visible')[0]
      if (isBarcodeScannerOpen && screen) {
        screen.style.visibility = 'hidden';
      }
      else if (screen && !isBarcodeScannerOpen) {
               screen.style.visibility = 'visible';
      }
    
        return (
            <div className={"app-layout "+this.getSelectedLanguage()}>
                {this.props.children}
                {Util.isBottomNavigationBarVisible() ?
                    <BottomNavigationBar setOffers={this.props.setOffers} isScannerVisible={isBarcodeScannerOpen} locale={this.props.locale} history={this.props.history} />:''
                }
                {Util.isFeedbackSurveyPopUpVisible() ?
                    <SurveyPopUp surveyVisible={this.state.surveyVisible} onSurveyPopUpClick = {() => this.setState({ surveyVisible: !this.state.surveyVisible })}    />:''
                }
                {isPreloaderVisible ?
                    <Preloader isSectionLoader={isSectionPreloader}/>:''
                }
                <div className={"scanner-wrapper "+(isBarcodeScannerOpen ? 'show' : 'hidden')}>
                    <ScanProductBarcodePopUp isBarcodeScannerOpen={isBarcodeScannerOpen} isError={isErrorCode} isErrorToast={isErrorToast} type={scannerType} history={this.props.history}/>
                </div>
                {isScannerPermissionError ? 
                    <ScanPermissionErrorPopUp history={this.props.history}/>:''
                }
                {isToastVisible ?
                    <CommonToast isError={isErrorCommonToast} message={toastMessage} toastType={toastType} actionLabel={actionLabel} actionCallback={actionCallback} toastHtmlContent={toastHtmlContent} hasClose={hasClose}/>:''
                } 
            </div>
        );
    }
    async setLocaleFile() {
        const selectedLanguage = Util.getStringFromStorage(Util.APP_LANGUAGE);
        let data;
        if (selectedLanguage) {
            try {
                data = await import('../../utils/languages/'+selectedLanguage+'.json');
            } catch (error) {
                data = await import('../../utils/languages/en-us.json');
            }
        } else {
            Util.addStringToStorage(Util.APP_LANGUAGE, 'en-us');
            data = await import('../../utils/languages/en-us.json');
        }
        if (data && data.default) {
            this.props.setLocale(data.default);
        }
    }
    getSelectedLanguage() {
        const language = Util.getStringFromStorage(Util.APP_LANGUAGE) || 'en-us';
        return language;
    }
}

const mapStateToProps = state => {
    return {
      locale: state.locale
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        setLocale: localeObj => dispatch(setLocaleData(localeObj)),
        setManualCodeVisibility: isVisible => dispatch(setManualCodeVisibility(isVisible)),
        setOffers: (storeId, request) => dispatch(setOffers(storeId, request)),
    };
  };
  
  const connectedLayout = connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout);
  
  export default withRouter(connectedLayout);
