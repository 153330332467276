import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Barcode,
  BarcodePicker,
  CameraAccess,
  CameraSettings,
  ScanSettings
} from "scandit-sdk";
// import ScanditBarcodeScanner from "scandit-sdk-react";
// import Quagga from "quagga";
import MainButton from "../../components/main-button/main-button";
import BarcodeInput from "../../components/barcode-input/barcode-input";
import MainLink from "../../components/main-link/main-link";
import closeIcon from "../../static/close-white.svg";
import closeDarkIcon from "../../static/close.svg";
import skullIcon from "../../static/skull.svg";
import barcodeGraphics from "../../static/barcode-graphic.svg";
import barcodeIcon from "../../static/barcode-white.svg";
import scanditLogo from "../../static/scandit-logo.png";
import ProductService from "../../services/product.service";
import FullScreenModal from '../../components/full-screen-modal/full-screen-modal';
import {
  setManualCodeVisibility,
  setScanErrorVisibility,
  setToastMessage,
  setToastVisibility
} from "../../actions/scan";
import { setCurrentCart } from "../../actions/cart";
import { setOffers } from '../../actions/offers';
import "./scan-product-barcode-popup.scss";
import Util from "../../utils/util";
import ScanditScanner from "../../components/scandit-scanner/scandit-scanner";
import { setCurrentProduct } from "../../actions/product";
import store from "../../reducers/store";

class ScanProductBarcodePopUp extends Component {
  state = {
    barCode: "",
    isScanPaused: true,
    showTermsModal: false
  };
  scanCount = 0;
  hasGotCouponCode = false;
  hasGotProductCode = false;
  hasDetected = false;
  isScanStarted = false;
  componentDidUpdate(prevProp) {
    if (prevProp.isErrorToast != this.props.isErrorToast) {
      this.showItemInvalidErrorToast();
    }
  }
  componentDidMount() {
    window.togglePauseScan = this.togglePauseScan;
    window.onScan = this.onScan;
    if (this.props.isError) {
      this.showErrorWindow();
    } else if (this.props.isErrorToast) {
      this.showItemInvalidErrorToast();
    } else {
      if (this.hasGetUserMedia()) {
        // this.startScanning();
      } else {
        // Google Analytics: Log scan error
        dataLayer.push({
          errorValue: "Scanner is not supported by your browser",
          event: "errorLogged",
          eventCallback: function() {
            Util.gotoRoute("/browser-incompatible", this.props.history);
          },
          eventTimeout: 3000
        });
      }
    }
  }
  showItemInvalidErrorToast() {
    this.props.setManualCodeVisibility(false);
    this.showInvalidProductErrorToast();
  }
  showErrorWindow() {
    this.props.setScanErrorVisibility(true);
    this.props.setManualCodeVisibility(false);
  }
  hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }
  startScanning() {
    this.togglePauseScan(false);
  }
  onReady() {
    const logoImageObj = document.querySelector('#scan-camera .scandit .scandit-barcode-picker img.scandit-logo');
    if (logoImageObj) {
      logoImageObj.src = scanditLogo;
    }
    const shadowDomMask = document.getElementById('shadow-dom-mask');
    if (shadowDomMask) {
      shadowDomMask.style.display = "none";
    }
    Util.removeStringFromStorage(Util.CAMERA_PERMISSION_FLAG);
    if (this.scanCount >= 2) {
      this.showGuidanceToast();
    }
    if (this.props.isBarcodeScannerOpen) {
      dataLayer.push({
        'event': 'dataLayer-initialized',
        'customAttributes':Util.getCustomAttributes(),
        'userID': Util.getEmailId(),
        'language': Util.getSelectedLanguage(),
        'pageName': 'barcode scanner',
        'pageType': 'barcode scanner',
        'storeID': Util.getStoreDetails().storeId,
        'storeDesc': Util.getStoreDetails().name,
        'flowConfig': Util.getGAFlowConfig(),
        signedIn: (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN) ? 'signed-in user' : 'guest'
      });
      dataLayer.push({
        'event': 'appPageView',
        'appPageName': 'barcode scan page',
        'flowConfig': Util.getGAFlowConfig()
      });
      // GA Tapestry
      dataLayer.push({
        'event': 'flowInteraction',
        'flowAction': 'camera access granted',
        'flowConfig': Util.getGAFlowConfig()
      }); 
    }
  }
  onScannerInit() {
    
  }
  onProcess() {
    
  }
  onScan = (scanResult, fromNative = false) => {
    console.log('on scan function')
    let data = {};
    if (fromNative) {
      console.log('on scan is from native')
      data.codeResult = {
        code: scanResult
      };
    } else {
      data = scanResult && scanResult.barcodes && scanResult.barcodes.length ? scanResult.barcodes[0] : '';
      data.codeResult = {
        code: ''
      };
      data.codeResult.code = scanResult.barcodes.reduce(function(string, barcode) {
        let barcodeResult = barcode.data;
        if (barcode.symbology.indexOf('upc') >= 0) {
          barcodeResult = barcode.data.substring(1);
        }
        return barcodeResult;
      }, "");
    }
    
    if (data && data.codeResult && data.codeResult.code) {
      if (data.codeResult.code && !this.hasDetected) {
        this.togglePauseScan(true);
        this.isScanStarted = false;
        this.hasDetected = false;
        if (
          data.codeResult.code.length === Util.PRODUCT_BARCODE_COUNT_1 ||
          data.codeResult.code.length === Util.PRODUCT_BARCODE_COUNT_2
        ) {
            // Resetting error scan count
            this.scanCount = 0;
            
            // Google Analytics: Log scan code length
            dataLayer.push({
              logText: `Scanned ${
                data.codeResult.code.length
              } digit product code`,
              event: "logText"
            });

            // GA Tapestry
            // dataLayer.push({
            //   'event': 'barcodeScanningSuccess',
            //   'upc': data.codeResult.code,
            //   'barcodeActionType':'auto',
            //   'flowConfig': Util.getGAFlowConfig(),
            //   eventCallback: () => {
                // window.toggleBarcodeScanner(false);
                this.hasGotProductCode = true;
                Util.resetColorSwatchFlag();
                // this.props.history.push(`/product/${data.codeResult.code}`);
                // Util.gotoRoute(`/product/${data.codeResult.code}`, this.props.history);
                this.validateProduct(data.codeResult.code, true);
            //   },
            //   eventTimeout: 2000
            // });
        } else if (
          data.codeResult.code.length === Util.COUPON_BARCODE_COUNT
        ) {
            // Resetting error scan count
            this.scanCount = 0;

            // Google Analytics: Log scan code length
            dataLayer.push({
              logText: `Scanned ${
                Util.COUPON_BARCODE_COUNT
              } digit coupon code`,
              event: "logText"
            });

            // GA Tapestry
            dataLayer.push({
              'event': 'barcodeScanningSuccess',
              'barcodeActionType': 'auto',
              'upc': undefined,
              'id':data.codeResult.code,
              'name': undefined,
              'variant': undefined,
              eventCallback: () => {
                Util.removeFromSessionStorage(Util.REQUEST_INLINE_BUTTON_VISIBLE);
                Util.removeFromSessionStorage(Util.HIDE_SAVE_TO_BAG_BUTTON);
                this.validateCoupon(data.codeResult.code);
                this.hasGotCouponCode = true;
              },
              eventTimeout: 2000
            });
        } else {
          this.scanCount++;
          this.showProductScanErrorToast({
            event_category: "in-store",
            event_label: "Invalid product or coupon code scanned"
          });
          // this.onErrorScan({
          //   event_category: "in-store",
          //   event_label: "Invalid product or coupon code scanned"
          // });
        }
      }
    } else {
      this.scanCount++;
      this.showProductScanErrorToast({
        event_category: "in-store",
        event_label: "Scanner did not send a valid response"
      });
      // this.onErrorScan({
      //   event_category: "in-store",
      //   event_label: "Scanner did not send a valid response"
      // });
    }
  }
  onScanError(err) {
    if (err) {
      dataLayer.push({
        'event': 'barcodeScanningError',
        'barcodeActionType': 'auto',
        'errorId': undefined,
        'errorDesc': 'Scandit scanner error',
        'flowConfig': Util.getGAFlowConfig()
      });
      window.togglePreloader(false);
      // if (err.name === "NotAllowedError") {
      //   window.togglePreloader(true);
      //   window.toggleScanPermissionErrorWindow();
      //   window.togglePreloader(false);
      //   // GA Tapestry
      //   dataLayer.push({
      //     'event': 'flowInteraction',
      //     'flowAction': 'camera access denied',
      //     'flowConfig': Util.getGAFlowConfig()
      //   });
      // }
      // this.onErrorScan({
      //   event_category: "in-store",
      //   event_label: `Scanner Error: ${err.name}, ${err.message}`
      // });
      return;
    }
  }
  getScanSettings = () => {
    return new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.EAN13, Barcode.Symbology.UPCA, Barcode.Symbology.UPCE, Barcode.Symbology.CODE128, Barcode.Symbology.CODE39],
      codeDuplicateFilter: 1000
    });
  };
  
  togglePauseScan = (isPaused = true) => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent)&& 
    !/Edg/.test(navigator.userAgent)){
    if (isPaused) {
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.ios.postMessage("pauseCamera");
      }
    } else {
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.ios.postMessage("resumeCamera");
      }
    }
  }
    this.setState({isScanPaused: isPaused},_ => {
      if (window.barcodePicker) {
        if (isPaused) {
          window.barcodePicker.pauseScanning(true);
        } else {
          if (window.barcodePicker) {
            this.hasDetected = false;
            this.hasGotProductCode = false;
            window.togglePreloader(true);
            window.barcodePicker.accessCamera().then(picker => {
                window.barcodePicker = picker;
                window.barcodePicker.resumeScanning(true);
                window.togglePreloader(false);
            })
            .catch(err => {
                console.log(err);
                window.togglePreloader(false);
            });
          }
        }
      }
    });
  }

  termsOfUseLink(){
        dataLayer.push({
            'event': 'outboundLink',
            'linkAction': 'Terms and conditions'
           });
    }
    privacyPolicyLink(){
        dataLayer.push({
            'event': 'outboundLink',
            'linkAction': 'Privacy Policy'
           });
    }

  toggleTermsOfUseModal = () => this.setState({showTermsModal: !this.state.showTermsModal})
  render() {
    let barcodeScanning = {};
    let common = {};
    let errorCopyText = {};
    if (this.props.locale && this.props.locale.screens) {
      barcodeScanning = this.props.locale.screens.barcodeScanning;
      errorCopyText = this.props.locale.screens.error;
      common = this.props.locale.screens.common;
    }
    const { barCode, isScanPaused } = this.state;
    const {
      isEnterProductCodeVisible,
      isScanError,
      toastVisible,
      toastMessage,
      type
    } = this.props;
    if(isEnterProductCodeVisible && document.querySelector('.product-barcode-scan-error')) {
      document.querySelector('.product-barcode-scan-error').focus();
    }

    return (
      <>
          {/* SCANNER BLOCK START */}
          <div className={"scan-product-barcode "+(!isEnterProductCodeVisible && !isScanError ? 'show' : 'hidden')} tabIndex="0" aria-label="Scan Product Barcode">
          {this.state.showTermsModal ?
                    <FullScreenModal className="termsofuse" onClose={() => this.toggleTermsOfUseModal(false)}>
                        <div className="terms-content">
                            <h2>{common.termsAndConditions}</h2>
                            <p>
                            {common.fullDesclaimerNew}
                                <a target='_blank' href='https://www.coachoutlet.com/terms-of-use.html' onClick={this.termsOfUseLink} aria-describedby='new-window-0'> {common.termsOfUse} <span hidden>opens in a new window</span></a> {common.and} <a target='_blank' href='https://www.coachoutlet.com/privacy.html' onClick={this.privacyPolicyLink} aria-describedby='new-window-0'> {common.privacyPolicy} <span hidden>opens in a new window</span></a> {common.fullDisclaimerPost}
                                {common.fullDesclaimerNew2}
                                {common.fullDesclaimerNew3}

                            </p>
                        </div>
                    </FullScreenModal>:''
                }
            <div className = "header-container">
              <span className="header-label" >
              {common.byUsingThisApp}<MainLink className="main-link" onClick={this.toggleTermsOfUseModal.bind(this)} style={{fontSize:'14px !important'}}>{common.termsOfUse}</MainLink>
              </span>
            </div>
            {toastVisible ? (
              <div tabIndex="0" aria-live="assertive" role="alert" aria-atomic="true" className="toast-container">
                {toastMessage.map((message, index) => {
                  return <span  key={"toast-" + index}>{message}</span>;
                })}
              </div>
            ) : (
              ""
            )}
            <img
              alt="Close"
              aria-label="close scanner"
              tabIndex="0"
              role="button"
              src={closeIcon}
              className="close-icon"
              onClick={this.onScanWindowClose.bind(this)}
            />
            {/* <span className="camera-label heading">{barcodeScanning.scanMessage}</span> */}
            <div className="target-square">
              <div className="innerblock top" />
              <div className="innerblock bottom" />
            </div>
            <div className="scan-camera" id="scan-camera">
              <ScanditScanner 
                playSoundOnScan={false}
                vibrateOnScan={false}
                enableCameraSwitcher={false}
                enableTorchToggle={false}
                enableTapToFocus={true}
                scanningPaused={isScanPaused}
                accessCamera={!isScanPaused}
                guiStyle={BarcodePicker.GuiStyle.NONE}
                videoFit={BarcodePicker.ObjectFit.COVER}
                scanSettings={this.getScanSettings()}
                onReady={this.onReady.bind(this)}
                onProcessFrame={this.onProcess.bind(this)}
                onScan={this.onScan.bind(this)}
                onError={this.onScanError.bind(this)}
              />
            </div>
            <div id="shadow-dom-mask"></div>
            <div className="mask"></div>
            <div className="entermanually-container">
              <span
                tabIndex="0"
                role="link"
                className="enter-manually-link"
                onClick={this.onEnterManually.bind(this)}
              >
                {common.enterManually}
              </span>
            </div>
            <div className="mask-contents">
            <img src={barcodeIcon} alt="barcode region" tabIndex="0"/>
              {type == Util.SCANNER_OFFER_TYPE ?
                <>
                  <span className="help-text first">{barcodeScanning.recievedOffer}</span>
                  <span className="help-text last">{barcodeScanning.scanCodeAddPromotion}</span>
                </>:
                <>
                  <span className="help-text first">{barcodeScanning.scanBarcodeCheckPrice}</span>
                  <span className="help-text last">{barcodeScanning.seeYourSavings}</span>
                </>
              }
            {/* <div className="gradient-block" /> */}
            </div>
          </div>
          {/* SCANNER BLOCK END */}

          {/* SCANNER MANUAL ENTRY BLOCK */}
          <div className={"enter-product-barcode "+(isEnterProductCodeVisible && !isScanError ? 'show' : 'hidden')} aria-label="enter product barcode" tabIndex="0">
            <img
              alt="Close"
              aria-label="Close enter product barcode page"
              tabIndex="0"
              role="button"
              src={closeDarkIcon}
              className="close-icon"
              onClick={this.onManualPopupClose.bind(this)}
            />
            <span className="info-label" htmlFor="barcode-input">{barcodeScanning.manualInfoText1}</span>
            <span className="info-label" htmlFor="barcode-input">{barcodeScanning.manualInfoText2}</span>
            <span className="small-label" htmlFor="barcode-input">({barcodeScanning.barcodeLengthInfo})</span>
            <img alt="barcode region" src={barcodeGraphics} className="barcode-image" />
            <div className="action-container">
              <BarcodeInput
                onChange={this.onBarcodeChange.bind(this)}
                value={barCode}
              />
            </div>
            <div className="action-container">
              <MainButton
                text={barcodeScanning.buttonText}
                aria-disabled={!this.isValidBarcode(barCode)}
                onClick={this.onSubmitBarcode.bind(this)}
              />
            </div>
          </div>
          {/* SCANNER MANUAL ENTRY BLOCK END */}
          
          {/* SCANNER ERROR BLOCK START */}
          <div className={"product-barcode-scan-error "+(isScanError ? 'show' : 'hidden')} aria-label="product barcode scan error">
            <img
              alt="Close"
              aria-label="Close"
              tabIndex="1"
              role="button"
              src={closeDarkIcon}
              className="close-icon"
              onClick={this.onManualPopupClose.bind(this)}
            />
            <h2>{errorCopyText.oops}</h2>
            <label className="first">{errorCopyText.commonScanError1}</label>
            <label className="last">{errorCopyText.commonScanError2}</label>
            <img alt="error image" src={skullIcon} className="error-image" />
            <MainLink onClick={this.onEnterManually.bind(this)}>
              {common.enterManually}
            </MainLink>
            <MainLink
              className="last"
              onClick={this.onManualPopupClose.bind(this)}
            >
              {errorCopyText.tryScanAgain}
            </MainLink>
            <div className="bottom-text">
              <span>{errorCopyText.stillHavingIssue}</span>
              <span>{errorCopyText.seeStoreAssoiciate}</span>
            </div>
          </div>
          {/* SCANNER ERROR BLOCK END */}
      </>
    );
  }
  showGuidanceToast() {
    let errorCopyText = {};
    if (this.props.locale && this.props.locale.screens) {
      errorCopyText = this.props.locale.screens.error;
    }
    this.props.setToastMessage([
      errorCopyText.scanningAdvice1, 
      errorCopyText.scanningAdvice2
    ]);
    this.props.setToastVisibility(true);
    setTimeout(() => {
      this.props.setToastVisibility(false);
    }, 2500);
  }
  showProductScanErrorToast(gtmObj) {
    let errorCopyText = {};
    if (this.props.locale && this.props.locale.screens) {
      errorCopyText = this.props.locale.screens.error;
    }
    if (!this.props.isError && !this.props.isErrorToast) {
      this.togglePauseScan(true);
      this.isScanStarted = false;
    }
    this.onManualPopupClose(false);
    this.props.setToastMessage([
      errorCopyText.scanError1, 
      errorCopyText.scanError2
    ]);
    this.props.setToastVisibility(true);
    setTimeout(() => {
      this.props.setToastVisibility(false);
      this.startScanning();
      this.hasGotCouponCode = false;
      this.hasGotProductCode = false;
      this.hasDetected = false;
    }, 2500);
    // Google Analytics: Log scan error
    dataLayer.push({
      errorValue: gtmObj.event_label,
      event: "errorLogged"
    });
  }
  showInvalidProductErrorToast(auto) {
    if (!this.props.isError && this.isScanStarted) {
      this.togglePauseScan(true);
      this.isScanStarted = false;
    }
    let errorCopyText = {};
    if (this.props.locale && this.props.locale.screens) {
      errorCopyText = this.props.locale.screens.error;
    }
    this.onManualPopupClose(false);
    this.props.setToastMessage([
      errorCopyText.itemNotFoundError1, 
      errorCopyText.itemNotFoundError2
    ]);
    this.props.setToastVisibility(true);
    setTimeout(() => {
      const shadowDomMask = document.getElementById('shadow-dom-mask');
      if (shadowDomMask) {
        shadowDomMask.style.display = "none";
      }
    }, 2550);
    setTimeout(() => {
      this.props.setToastVisibility(false);
      this.hasGotCouponCode = false;
      this.hasGotProductCode = false;
      this.hasDetected = false;
      this.startScanning();
    }, 2500);
    

      // Google Analytics: Log scan error
    //   dataLayer.push({
    //     errorValue: gtmObj.event_label,
    //     event: "errorLogged"
    //   });
    // }
  }
  onBarcodeChange(e) {
    this.setState({ barCode: e.target.value });
  }
  onErrorScan(gtmObj) {
    // Google Analytics: Log scan error
    dataLayer.push({
      errorValue: gtmObj.event_label,
      event: "errorLogged"
    });

    if (!this.props.isError && !this.props.isErrorToast) {
      this.togglePauseScan(true);
      this.isScanStarted = false;
    }
    this.props.setScanErrorVisibility(true);
    this.props.setManualCodeVisibility(false);
  }
  onEnterManually() {
    // GA Tapestry
    // Google Analytics: Log click of enter manually link
    dataLayer.push({
      logText: "Link Click - Enter product or coupon code manually",
      event: "logText"
    });

    if (!this.props.isError && !this.props.isErrorToast) {
      this.togglePauseScan(true);
      this.isScanStarted = false;
    }
    this.setState({ barCode: "" });
    this.props.setScanErrorVisibility(false);
    this.props.setManualCodeVisibility(true);
  }
  onManualPopupClose(isScanRestart = true) {
    this.props.setScanErrorVisibility(false);
    this.props.setManualCodeVisibility(false);
    if (isScanRestart) {
      this.startScanning();
    }
  }
  onScanWindowClose() {
    this.togglePauseScan(true);
    window.toggleBarcodeScanner(false);
    this.isScanStarted = false;
    const currentRoute = window.location.pathname;

    switch (currentRoute.split("/")[1]) {
      case "email":
        // this.props.history.push('/cart');
        Util.gotoRoute('/cart', this.props.history);
        break;
      case "product":
        // this.props.history.push('/cart');
        Util.gotoRoute('/cart', this.props.history);
        break;
      case "store":
        // this.props.history.push('/cart');
        Util.gotoRoute('/cart', this.props.history);
        break;
        case "offers":
          // this.props.history.push("/offers");
          Util.gotoRoute('/offers', this.props.history);
          const storeDetail = Util.getStoreDetails();
          if (storeDetail && storeDetail.storeId) {
            const appliedCoupon = Util.getObjectFromStorage(Util.APPLIED_COUPONS) || [];
            const personalizedPromosList = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST) || [];
            const request = {
              couponNum: [],
              isLoggedIn: (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN).toString()
            };
            appliedCoupon.forEach(coupon => {
              if (coupon && coupon.couponId) {
                request.couponNum.push(coupon.couponId);
              }
            });
            request.personalizedPromosList = personalizedPromosList;
            this.props.setOffers(storeDetail.storeId, request);
          }
          window.toggleBarcodeScanner(false);
          break;
      default:
        window.toggleBarcodeScanner(false);
        break;
    }
  }
  isValidBarcode() {
    let isValid = false;
    const rawbarcode = Util.getRawBarcode(this.state.barCode);
    if (
      rawbarcode.length === Util.PRODUCT_BARCODE_COUNT_1 ||
      rawbarcode.length === Util.PRODUCT_BARCODE_COUNT_2 ||
      rawbarcode.length === Util.COUPON_BARCODE_COUNT
    ) {
      isValid = true;
    }
    return isValid;
  }
  onSubmitBarcode() {
    // dataLayer.push({
    //   event: 'barcodeScanningInteraction',
    //   barcodeAction: 'enter product or coupon code submit',
    //   enteredProductId: this.state.barCode
    // });
    const rawbarcode = Util.getRawBarcode(this.state.barCode);
    if (
      rawbarcode.length === Util.PRODUCT_BARCODE_COUNT_1 ||
      rawbarcode.length === Util.PRODUCT_BARCODE_COUNT_2
    ) {
      this.props.setManualCodeVisibility(false);
      // Google Analytics: Log button click after product code manual entry
      dataLayer.push({
        event: 'barcodeScanningManualEntry',
        barcodeAction: 'enter product or coupon code submit',
        enteredProductId:rawbarcode
      });
      Util.resetColorSwatchFlag();
      this.validateProduct(rawbarcode,false);
      // dataLayer.push({
      //   'event': 'barcodeScanningSuccess',
      //   'upc': rawbarcode,
      //   'id':this.state.barCode,
      //   'flowConfig': Util.getGAFlowConfig(),
      //   eventCallback: () => {
      //     Util.resetColorSwatchFlag();
      //     // window.toggleBarcodeScanner(false);
      //     // this.props.history.push(`/product/${rawbarcode}`);
      //     // Util.gotoRoute(`/product/${rawbarcode}`, this.props.history);
      //     this.validateProduct(rawbarcode);
      //   },
      //   eventTimeout: 2000
      // });
    } else if (rawbarcode.length === Util.COUPON_BARCODE_COUNT) {
      this.props.setManualCodeVisibility(false);
      // Google Analytics: Log button click after coupon code manual entry
      dataLayer.push({
        event: 'barcodeScanningManualEntry',
        barcodeAction: 'enter product or coupon code submit',
        enteredProductId:rawbarcode
      });
      dataLayer.push({
        'event': 'barcodeScanningSuccess',
        'barcodeActionType': 'manual',
        'upc': undefined,
        'id':rawbarcode,
        'name': undefined,
        'variant': undefined,
        eventCallback: () => {
          this.validateCoupon(rawbarcode);
        },
        eventTimeout: 2000
      });
    }
  }
  isDuplicateCoupon(couponCode) {
    let isDuplicate = false;
    const appliedCoupons =
      Util.getObjectFromStorage(Util.APPLIED_COUPONS) || [];
    if (appliedCoupons && appliedCoupons.length && Util.isCouponApplied(couponCode)) {
      // Google Analytics: Log duplicate entry of a coupon
      dataLayer.push({
        logText: "Detect duplicate entry of a coupon",
        event: "logText"
      });
      isDuplicate = true;
    }
    return isDuplicate;
  }
  validateProduct(productId, auto) {
    const storeDetail = Util.getStoreDetails();
    this.props.setCurrentProduct(storeDetail.storeId, productId)
      .then(data => {
        if (data && data.itemId) {
          dataLayer.push({
            'event': 'barcodeScanningSuccess',
            'barcodeActionType': auto ? 'auto':'manual',
            'upc': data.itemId,
            'id':data.itemId,
            'name': data.productName,
            'variant': Util.getVariant(data.styleNo, data.colorCode, data.size),
          });
          window.toggleBarcodeScanner(false);
          this.hasDetected = false;
          this.hasGotProductCode = false;
          window.scrollTo(0,0);
          Util.removeFromSessionStorage(Util.REQUEST_INLINE_BUTTON_VISIBLE);
          Util.removeFromSessionStorage(Util.HIDE_SAVE_TO_BAG_BUTTON);
          Util.removeFromSessionStorage(Util.IS_ECATALOGUE_FLOW);
          Util.gotoRoute(`/product/${data.itemId}`, this.props.history);
        } else {
            // GA Tapestry
            dataLayer.push({
              'event': 'barcodeScanningError',
              'barcodeActionType':  auto ? 'auto':'manual',
              'errorId': undefined,
              'errorDesc': 'Invalid Id',
              'flowConfig': Util.getGAFlowConfig()
            });
          dataLayer.push({
            errorValue: "No products available for this product id",
            event: "errorLogged",
            eventCallback: () => {
              this.showInvalidProductErrorToast(auto);
            },
            eventTimeout: 2000
          });
        }
      })
      .catch(err => {
        
      });
  }
  processValidateCouponResponse(data, couponCode) {
    let errorCopyText = {};
    if (this.props.locale && this.props.locale.screens) {
      errorCopyText = this.props.locale.screens.error;
    }
    if (data && data.status === "Success") {
      if (data.isPromoAutoApplyInStore.toString() == "false") {
        const appliedCoupons = Util.getObjectFromStorage(Util.APPLIED_COUPONS) || [];
        if (data.promotion && data.promotion.couponId) {
          if (appliedCoupons && appliedCoupons.length) {
            if (!Util.isCouponApplied(couponCode)) {
              appliedCoupons.push(data.promotion);
            }
          } else {
            appliedCoupons.push(data.promotion);
          }
        } else {
          if (appliedCoupons && appliedCoupons.length) {
            if (!Util.isCouponApplied(couponCode)) {
              appliedCoupons.push(couponCode);
            }
          } else {
            appliedCoupons.push(couponCode);
          }
        }
        
        Util.addObjectToStorage(Util.APPLIED_COUPONS, appliedCoupons);
        const cartDetail = Util.getCart();
        if (cartDetail && cartDetail.length > 0) {
          this.onScanWindowClose();
          if (window.location.pathname.indexOf('/cart') >= 0) {
            const storeDetail = Util.getStoreDetails();
            const appliedCoupon = Util.getObjectFromStorage(Util.APPLIED_COUPONS) || [];
            const personalizedPromosList = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST) || [];
            const request = {
              itemIds: [],
              couponNum: [],
              userAppliedCouponNum: Util.getStringFromStorage(Util.ADDITIONAL_APPLIED_COUPON) || "",
              isLoggedIn: (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN).toString(),
            };
            cartDetail.forEach((cartItem, index) => {
              if (cartItem && cartItem.itemId) {
                request.itemIds.push({itemId: cartItem.itemId, isRequested: cartItem.isRequested});
              }
            });
            appliedCoupon.forEach(coupon => {
              if (coupon && coupon.couponId) {
                request.couponNum.push(coupon.couponId);
              }
            });
            request.personalizedPromosList = personalizedPromosList;
            this.hasDetected = false;
            this.hasGotProductCode = false;
            this.props.setCart(storeDetail.storeId, request);
          } else {
            // this.props.history.push('/cart');
            this.hasDetected = false;
            this.hasGotProductCode = false;
            Util.gotoRoute('/cart', this.props.history);
          }
        } else {
          this.onManualPopupClose(false);
          this.setState({ barCode: "" });
          this.props.setToastVisibility(true);
          this.props.setToastMessage([
            errorCopyText.emptyCartCouponScan1,
            errorCopyText.emptyCartCouponScan2
          ]);
          setTimeout(() => {
            const shadowDomMask = document.getElementById('shadow-dom-mask');
            if (shadowDomMask) {
              shadowDomMask.style.display = "none";
            }
          }, 2550);
          setTimeout(() => {
            this.props.setToastVisibility(false);
            this.startScanning();
            this.hasGotCouponCode = false;
            this.hasGotProductCode = false;
            this.hasDetected = false;
          }, 2500);
        }
      } else {
        this.onManualPopupClose(false);
        if (this.isScanStarted) {
          this.togglePauseScan(true);
          this.isScanStarted = false;
        }
        if (data.redeemed == true) {
          this.props.setToastVisibility(true);
          this.props.setToastMessage([errorCopyText.redeemedCoupon]);
        } else {
          this.props.setToastVisibility(true);
          this.props.setToastMessage([errorCopyText.duplicateCoupon]);
        }
        setTimeout(() => {
          const shadowDomMask = document.getElementById('shadow-dom-mask');
          if (shadowDomMask) {
            shadowDomMask.style.display = "none";
          }
        }, 2550);
        setTimeout(() => {
          this.props.setToastVisibility(false);
          this.hasGotCouponCode = false;
          this.hasGotProductCode = false;
          this.hasDetected = false;
          this.startScanning();
        }, 2500);
      }
    } else {
      // Google Analytics: Log invalid coupon code
      dataLayer.push({
        logText: "Invalid coupon code entered",
        event: "logText",
        eventCallback: () => {
          this.hasGotCouponCode = false;
          this.hasGotProductCode = false;
          this.hasDetected = false;
          this.showInvalidProductErrorToast();
        },
        eventTimeout: 2000
      });
    }
  }
  validateCoupon(couponCode) {
    let errorCopyText = {};
    if (this.props.locale && this.props.locale.screens) {
      errorCopyText = this.props.locale.screens.error;
    }
    if (this.isDuplicateCoupon(couponCode)) {
      this.onManualPopupClose(false);
      if (this.isScanStarted) {
        this.togglePauseScan(true);
        this.isScanStarted = false;
      }
      this.props.setToastMessage([errorCopyText.duplicateCoupon]);
      this.props.setToastVisibility(true);
      setTimeout(() => {
        const shadowDomMask = document.getElementById('shadow-dom-mask');
        if (shadowDomMask) {
          shadowDomMask.style.display = "none";
        }
      }, 2550);
      setTimeout(() => {
        this.props.setToastVisibility(false);
        this.hasGotCouponCode = false;
        this.hasGotProductCode = false;
        this.hasDetected = false;
        this.startScanning();
      }, 2500);
    } else {
      const storeDetails = Util.getStoreDetails();
      const request = {
        couponNum: couponCode,
        isLoggedIn: (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN).toString(),
      };
      if (Util.isSUCCoupon(couponCode)) {
        const sucCouponValidatinRequest = {
          coupon: couponCode
        };
        ProductService.validateSUCCoupon(sucCouponValidatinRequest)
          .then(data => {
            this.processValidateCouponResponse(data, couponCode);
          })
          .catch(error => {
            this.hasGotCouponCode = false;
            this.hasGotProductCode = false;
            this.hasDetected = false;
            this.showErrorWindow();
          });
      } else {
        ProductService.validateCoupon(storeDetails.storeId, request)
        .then(data => {
          this.processValidateCouponResponse(data, couponCode);
        })
        .catch(error => {
            this.hasGotCouponCode = false;
            this.hasGotProductCode = false;
            this.hasDetected = false;
            this.showErrorWindow();
        });
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    isEnterProductCodeVisible: state.scan.isEnterProductCodeVisible,
    isScanError: state.scan.isScanError,
    toastVisible: state.scan.toastVisible,
    toastMessage: state.scan.toastMessage,
    locale: state.locale
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: message => dispatch(setToastMessage(message)),
    setToastVisibility: isVisible => dispatch(setToastVisibility(isVisible)),
    setManualCodeVisibility: isVisible =>
      dispatch(setManualCodeVisibility(isVisible)),
    setScanErrorVisibility: isVisible =>
      dispatch(setScanErrorVisibility(isVisible)),
    setOffers: (storeId, request) => dispatch(setOffers(storeId, request)),
    setCart: (storeId, request) => dispatch(setCurrentCart(storeId, request)),
    setCurrentProduct: (storeId, itemId) => dispatch(setCurrentProduct(storeId, itemId))
  };
};

const connectedScan = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanProductBarcodePopUp);

export default connectedScan;
