import React from "react";
import LanguageSelectorList from "../language-selector-list/language-selector-list";
import Util from "../../utils/util";
import "./bottom-navigation-bar.scss";
import offersIcon from "../../static/offers.svg";
import offersSelectedIcon from "../../static/offers-selected.svg";
import languageIcon from "../../static/language.svg";
import languageSelectedIcon from "../../static/language-selected.svg";
import cameraIcon from "../../static/camera.svg";
import cameraSelectedIcon from "../../static/camera-selected.svg";
import shoppingBagIcon from "../../static/shopping-bag.svg";
import shoppingBagSelectedIcon from "../../static/shopping-bag-selected.svg";
import closeIcon from "../../static/close.svg";
import accountSelectedIcon from "../../static/account-selected.svg";
import accountIcon from "../../static/account.svg";
import homeSelectedIcon from "../../static/home-selected.svg";
import homeIcon from "../../static/home.svg";

export default class BottomNavigationBar extends React.Component {
  state = {
    isLanguageListVisible: false
  };
  componentDidMount() {}

  render() {
    let common = {};
    if (this.props.locale && this.props.locale.screens) {
      common = this.props.locale.screens.common;
    }
    const cartProducts = Util.getCart() || [];
    const { isLanguageListVisible } = this.state;
    return (
      <>
        {isLanguageListVisible && (
          <LanguageSelectorList
            closeIcon={closeIcon}
            localeCommon={common}
            onLanguageChange={this.onLanguageChange.bind(this)}
            onClose={() => {
              this.toggleLanguagePopup(false);
            }}
            visible={isLanguageListVisible}
          />
        )}
        <div aria-hidden="false" className="bottom-navigation-bar">
          <button
            className="navigation-item"
            onClick={this.onNavigationItemClick.bind(this, "home")}
            role="tab"
            aria-label="home"
            aria-selected={window.location.pathname.indexOf("/home") > -1 && !this.props.isScannerVisible ? true:false}

            // aria-expanded={isLanguageListVisible}
          >
            {window.location.pathname.indexOf("/home") > -1 || window.location.pathname.indexOf("/product-categories") > -1 || window.location.pathname.indexOf("/product-listing") > -1 &&
            !this.props.isScannerVisible ? (
              <div className="icon-container">
                <img src={homeSelectedIcon} alt="home icon selected" />
              </div>
            ) : (
              <div className="icon-container">
                <img src={homeIcon} alt="home icon" />
              </div>
            )}
            <label>{common.home}</label>
          </button>
          <button
            className="navigation-item"
            onClick={() => this.onNavigationItemClick("offers")}  

            aria-label="Rewards"
            aria-selected={window.location.pathname.indexOf("/offers") > -1 && !this.props.isScannerVisible ? true:false}
            role="tab"
          >
            {window.location.pathname.indexOf("/offers") > -1 &&
            !this.props.isScannerVisible ? (
              <div className="icon-container">
                <img src={offersSelectedIcon} alt="offer icon selected" />
              </div>
            ) : (
              <div className="icon-container">
                <img src={offersIcon} alt="offer icon" />
              </div>
            )}
            <label>{common.offers}</label>
          </button> 
          <button
            className="navigation-item"
            onClick={this.onNavigationItemClick.bind(this, "scan")}
            aria-label="scan"
            aria-selected={this.props.isScannerVisible ? true:false}
            role="tab"
          >
            {this.props.isScannerVisible ? (
              <div className="icon-container">
                <img src={cameraSelectedIcon} alt="camera icon selected" />
              </div>
            ) : (
              <div className="icon-container">
                <img src={cameraIcon} alt="camera icon" />
              </div>
            )}
            {/* <div className="icon-container">
                            <img src={cameraIcon} />
                        </div> */}
            <label>{common.scan}</label>
          </button>
          <button
            className="navigation-item"
            onClick={this.onNavigationItemClick.bind(this, "account")}
            aria-label="account"
            aria-selected={window.location.pathname.indexOf("/account") > -1 && !this.props.isScannerVisible ? true:false}
            role="tab"
            // aria-expanded={isLanguageListVisible}
          >
            {window.location.pathname.indexOf("/account") > -1 &&
            !this.props.isScannerVisible ? (
              <div className="icon-container">
                <img src={accountSelectedIcon} alt="account icon selected" />
              </div>
            ) : (
              <div className="icon-container">
                <img src={accountIcon} alt="account icon" />
              </div>
            )}
            <label>{common.account}</label>
          </button>
          <button
            className="navigation-item"
            onClick={this.onNavigationItemClick.bind(this, "cart")}
            aria-label="cart"
            aria-selected={(window.location.pathname.indexOf("/cart") > -1 || window.location.pathname.indexOf("/checkout") > -1) && !this.props.isScannerVisible ? true:false}
            role="tab"
          >
            {(window.location.pathname.indexOf("/cart") > -1 || window.location.pathname.indexOf("/checkout") > -1) &&
            !this.props.isScannerVisible ? (
              <div className="icon-container selected">
                <span>{cartProducts.length}</span>
                <img
                  src={shoppingBagSelectedIcon}
                  alt="shoppingbag icon selected"
                />
              </div>
            ) : (
              <div className="icon-container">
                <span>{cartProducts.length}</span>
                <img src={shoppingBagIcon} alt="shoppingbag icon" />
              </div>
            )}
            <label>{common.bag}</label>
          </button>
        </div>
      </>
    );
  }
  onNavigationItemClick(source) {
    const currentPage = window.location.pathname;
    switch (source) {
      case "home":
        dataLayer.push({
          event: "navigation",
          navigationLocation: "main menu",
          navigationDesc: "home",
          eventCallback: () => {
            if (window.isScannerOpen()) { 
              window.toggleBarcodeScanner(false); 
            }
            Util.gotoRoute("/home", this.props.history);
          },
          eventTimeout: 2000
        });
        break;
      case "account":
        dataLayer.push({
          event: "navigation",
          navigationLocation: "main menu",
          navigationDesc: "account",
          eventCallback: () => {
            if (window.isScannerOpen()) { 
              window.toggleBarcodeScanner(false); 
            }
            Util.gotoRoute("/account", this.props.history);
          },
          eventTimeout: 2000
        });
        break;
      case "offers":
        if (currentPage.indexOf("/product") > -1) {
          dataLayer.push({
            event: "pdpInteraction",
            pdpAction: "offers icon click",
            fullSKU:undefined,
            // flowConfig: Util.getGAFlowConfig(),
            eventCallback: () => {
              dataLayer.push({
                event: "navigation",
                navigationLocation: "main menu",
                navigationDesc: "rewards",
                eventCallback: () => {
                  // window.togglePreloader(true);
                  window.toggleBarcodeScanner(false);
                  // window.location.href = '/offers';
                  Util.gotoRoute("/offers", this.props.history);
                  // this.props.history.push('/offers');
                },
                eventTimeout: 2000
              });
            },
            eventTimeout: 2000
          });
        } else if (currentPage.indexOf("/cart") > -1) {
          dataLayer.push({
            event: "cartInteraction",
            cartAction:"offers icon click",
            cartLabel: undefined,
            eventCallback: () => {
              dataLayer.push({
                event: "navigation",
                navigationLocation: "main menu",
                navigationDesc: "rewards",
                eventCallback: () => {
                  window.toggleBarcodeScanner(false);
                  Util.gotoRoute("/offers", this.props.history);
                  // this.props.history.push('/offers');
                  // window.location.href = '/offers';
                },
                eventTimeout: 2000
              });
            },
            eventTimeout: 2000
          });
        } else if (currentPage.indexOf("/offers") > -1) {
          if (window.isScannerOpen()) {
            window.toggleBarcodeScanner(false);
            const storeDetail = Util.getStoreDetails();
            if (storeDetail && storeDetail.storeId) {
              const appliedCoupon =
                Util.getObjectFromStorage(Util.APPLIED_COUPONS) || [];
              const personalizedPromosList = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST) || [];
              const request = {
                couponNum: []
              };
              appliedCoupon.forEach(coupon => {
                if (coupon && coupon.couponId) {
                  request.couponNum.push(coupon.couponId);
                }
              });
              request.personalizedPromosList = personalizedPromosList;
              request.isLoggedIn = (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN).toString()
              this.props.setOffers(storeDetail.storeId, request);
            }
          }
        } else {
          dataLayer.push({
            event: "navigation",
            navigationLocation: "main menu",
            navigationDesc: "rewards",
            eventCallback: () => {
              window.toggleBarcodeScanner(false);
              Util.gotoRoute("/offers", this.props.history);
            },
            eventTimeout: 2000
          });
        }
        break;
      case "scan":
        if (currentPage.indexOf("/cart") > -1) {
          dataLayer.push({
            event: "cartInteraction",
            cartAction:"scanner icon click",
            cartLabel: undefined
          })
        }
        dataLayer.push({
          event: "navigation",
          navigationLocation: "main menu",
          navigationDesc: "scan",
          eventCallback: () => {
            window.toggleBarcodeScanner(true);
          },
          eventTimeout: 2000
        });
        dataLayer.push({
          event: "dataLayer-initialized",
          customAttributes:Util.getCustomAttributes(),
          userID: Util.getEmailId(),
          language: Util.getSelectedLanguage(),
          pageName: "barcode scanner",
          pageType: "barcode scanner",
          storeID: Util.getStoreDetails().storeId,
          storeDesc: Util.getStoreDetails().name,
          flowConfig: Util.getGAFlowConfig(),
          signedIn: (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN) ? 'signed-in user' : 'guest'
        });
        dataLayer.push({
          event: "barcodeScanningInteraction",
          barcodeAction: "scan item click",
          barcodeScanCTALocation: "Scan Navigation Button",
          barcodeScanCTA: "start scanning",
        });
        
        break;
      case "cart":
        dataLayer.push({
          event: "navigation",
          navigationLocation: "main menu",
          navigationDesc: "bag",
          eventCallback: () => {
            window.toggleBarcodeScanner(false);
            // this.props.history.push('/cart');
            Util.gotoRoute("/cart", this.props.history);
            // window.location.href = '/cart';
          },
          eventTimeout: 2000
        });
        break;
      default:
        break;
    }
  }
  toggleLanguagePopup() {
    this.setState(
      { isLanguageListVisible: !this.state.isLanguageListVisible },
      _ => {
        if (this.state.isLanguageListVisible) {
          document
            .querySelector(".language-selector-container-box")
            .removeAttribute("hidden");
          document.querySelector(".language-selector-container-box").focus();
        } else {
          document.querySelectorAll(".navigation-item")[1].focus();
        }
      }
    );
  }
  onLanguageChange(language) {
    window.togglePreloader(true);
    Util.addStringToStorage(Util.APP_LANGUAGE, language.value);
    window.location.reload();
  }
}
